import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { graphqlUrl } from '../config/settings.json';
// import { homeData } from '../config/graphQL.json';


export interface AppContextProps {
	AppData: any;
	setAppData: (AppStyles: any) => void;
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
}

interface AppContextProviderProps {
	children: any;
}

export const AppContext = React.createContext<AppContextProps>({
  AppData: {},
	setAppData: () => {},
  loading: false,
  setLoading: () => null,
});

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
	const [AppData, setAppData] = useState([{}]);
  const [loading, setLoading] = useState(false);

  //querySiteSettingsContents{
    function getData () {
      // try {
      //   setLoading(true);
      //   axios({
      //     url: graphqlUrl,
      //     method: "post",
      //     headers: {
      //       //Authorization: `Bearer ${token}`,
      //       "Content-Type": "application/json",
      //     },
      //     data: {
      //       query: `${homeData}`,
      //     },
      //   }).then((result) => {
         
      //     const data = result.data.data;
      //     setAppData(data)
          
      //   });
      // } catch (error) {
      //   console.log(error);
      //   alert('something went wrong')
      // } finally {
      //   setLoading(false);
      // }
      // setLoading(false);
    }



  useEffect(() => {
    getData();
    // getTheme();
  }, []);





	return (
		<AppContext.Provider
			value={{
        AppData,
        setAppData,
        loading,
        setLoading
			}}
		>
			{AppData &&  children}
		</AppContext.Provider>
	);
};

AppContext.displayName = 'AppContext';
