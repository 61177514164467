import React, { ReactElement, useState } from "react";

import "./Modal.scss";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  data: any;
  page: any;
}
export default function Modal(props: ModalProps): ReactElement {
  const { isShown, hide, data, page } = props;
  const pageContent = data.filter((v: any) => v.footerItemUrl.toLowerCase().includes(page));


  if (isShown === true ) {
    return (
     <div className="modal-background">
        <div className="modal-background-content" onClick={hide}></div>
        <div className="modal">
          <div className="modal-header">
            <div className="modal-header-text fc-blue-10 fs-6">
            {pageContent[0].footerItemTitle}
            </div>
            <div className="modal-header-button" onClick={hide}>
            <svg>
                    <use xlinkHref="#close" />
                  </svg>
            </div>
          </div>
          <div className="modal-content -pt2">
            <div className="modal-content-info">
                <div className="modal-content-info-row fc-blue-1 fs-5 fw-l" dangerouslySetInnerHTML={{ __html: pageContent[0].footerItemText,}} />
                
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
