import React, { ReactElement, useState, useEffect } from "react";
//import Animation from "./components/Animation";
import axios from "axios";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { graphqlUrl } from "../../config/settings.json";
import { lifeGQL } from "../../config/graphQL.json";
import ContactForm from "./components/ContactForm";
import { useOnScreenLazyValue } from "@huse/intersection";
import "./Life.scss";
import { Fade } from "react-awesome-reveal";
import Loader from "../../components/Loader";
import Animation from './components/Animation'

type Props = {};

function Life(props: Props): ReactElement {
  const [lifeData, setlifeData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  // const [headerTheme, setHeaderTheme] = useState("headerLight");
  const [activeItem, setActiveItem] = useState("");
  const [ref, isOnScreen] = useOnScreenLazyValue(true);

  const { hash } = window.location;

  
	if (hash !== '') {
	  setTimeout(() => {
		const id = hash.replace('#', '');
		const element = document.getElementById(id);
		if (element) element.scrollIntoView({behavior: "smooth", block: "start"});
	  }, 100);
	  removeHash ()
	}

	function removeHash () { 
		var scrollV, scrollH, loc = window.location;
			// Prevent scrolling by storing the page's current scroll offset
			scrollV = document.body.scrollTop;
			scrollH = document.body.scrollLeft;
			loc.hash = "";
			document.body.scrollTop = scrollV;
			document.body.scrollLeft = scrollH;
	}





  function getlifeData() {
    try {
      setLoading(true);
      axios({
        url: graphqlUrl,
        method: "post",
        headers: {
          //Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          query: `${lifeGQL}`,
        },
      }).then((result) => {
        const data = result.data.data;
        setlifeData(data.queryLifecontentContents);
      });
    } catch (error) {
      console.log(error);
      alert("something went wrong");
    } finally {
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    getlifeData();
  }, []);



  // if (intro !== "0") {
  //   setTimeout(() => {
  //     setShowIntro(false);
  //     sessionStorage.setItem("intro", "0");
  //   }, 4000);
  // }

  
  
  const intro = sessionStorage.getItem("intro");
  useEffect(() => {
    if (!intro) {
      setShowIntro(true)
      document.body.style.overflow = "hidden"
      setTimeout(() => {
        setShowIntro(false);
        sessionStorage.setItem("intro", "0");
      },5500);
    } else {
      document.body.style.overflow = "auto"
      if (intro==="0") {
        setShowIntro(true)
      }
    }
  }, [intro, setShowIntro]);




  return (
    <>
      {loading && <Loader /> }
      {!loading && lifeData[0] && (
        <>
        {intro !== "0" && showIntro && <Animation  animationData={lifeData[0]}  />}

        <div className={`lifeContent `}>
          <Header navData={lifeData[0].flatData.navigation} logo="life" />
          <div className="container">
            <div id="section-1" className={`section --fullWH section-1 light`} style={{ backgroundImage: `url(${lifeData[0].flatData.section1Background[0].url}?cache=600&quality=90&width=1920)`, }}>
              <div className="container --layout --fullH --centerV">
                <div className="texts --flex-col -white">
                  <Fade direction="down">
                  <div className={`title fs-6 fw-sb tt-caps`}>
                    {lifeData[0].flatData.section1Title}
                  </div>
                  </Fade>
                  <Fade direction="up" delay={100}>
                  <div className="subtitle  -pt12 fs-8 fw-eb tt-caps" dangerouslySetInnerHTML={{ __html: lifeData[0].flatData.section1Subtitle,}} />
                  </Fade> 
                  <Fade direction="up" delay={100}>
                  <div className={`text -pt85 fs-6 fw-sb tt-caps slideUp`} dangerouslySetInnerHTML={{__html: lifeData[0].flatData.section1Text,}}/>
                </Fade>
                </div>

              </div>
              
            </div>

            <div id="what-we-do" className={`section --fullWH section-2 dark`} 
            style={{ backgroundImage: `url(${lifeData[0].flatData.section2Background[0].url}?cache=600&quality=90&width=1920)`, }} >
              
              <div className="container --layout --fullH --centerV">
                <div className="texts --flex-col ">
                <Fade direction="down">
                  <div className="title fw-b tt-caps fc-blue-7 fs-4">{lifeData[0].flatData.section2Title}</div>
                  </Fade>
                  <Fade direction="up" delay={100}>
                  <div className="text -pt55 fs-7 fc-blue-2 fw-b tt-caps slideUp" dangerouslySetInnerHTML={{ __html: lifeData[0].flatData.section2Text,}}/>
                  </Fade>
                </div>
                <Fade direction="up">
                <div className="section-image -pt65">
                  <img src={`${lifeData[0].flatData.section2Image[0].url}?cache=600&quality=90&width=500&`} alt="" />
                </div>
                  </Fade>
              </div>

            </div>
            <div id="section-3" className="section --fullWH section-3 light" style={{ backgroundImage: `url(${lifeData[0].flatData.section3Background[0].url}?cache=600&quality=90&width=1920)`, }} >

              <div className="container --layout --fullH --centerV  --right">
                <div className="texts --flex-col fc-white">
                <Fade direction="down">
                  <div className="title fw-b tt-caps fs-4">{lifeData[0].flatData.section3Title}</div>
                  </Fade>
                <Fade direction="up">
                  <div className="text -pt65 fs-7  fw-b tt-caps" dangerouslySetInnerHTML={{ __html: lifeData[0].flatData.section3Text,}}/>
                  </Fade>
                </div>
                <div className="section-image -pt65">
                <Fade direction="up">
                  <img
                    src={`${lifeData[0].flatData.section3Image[0].url}?cache=600&quality=90&width=500`}
                    alt=""
                  />
                </Fade>
                </div>
              </div>
            </div>

            <div id="our-services" className="section --fullWH section-4 dark" style={{ backgroundImage: `url(${lifeData[0].flatData.section41Background[0].url}?cache=600&quality=90&width=1920)`, }} >
              <div className="container --layout --fullH --centerV">
                <div className="texts --flex-col fc-white">
                  <Fade direction="down">
                    <div className="title fw-b tt-caps  fs-4">
                    {lifeData[0].flatData.section41Title}
                  </div>
                  </Fade>
                  <Fade direction="up">
                  <div
                    className="text -pt12 fs-7-1  fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section41Subtitle,
                    }}
                  />
                  </Fade>
                  <Fade direction="up" delay={100}>
                  <div
                    className="text -pt12 fs-7-1 fc-blue-2 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section41Text,
                    }}
                  />
                  </Fade>
                <Fade direction="up">
                  <div className="section-image -pt65">
                  <img src={`${lifeData[0].flatData.section41Image[0].url}?cache=600&quality=90&width=500`} alt="" />
                </div>
                  </Fade>
                </div>
              </div>
            </div>


            <div id="" className="section --fullWH section-4 dark" style={{ backgroundImage: `url(${lifeData[0].flatData.section4Background[0].url}?cache=600&quality=90&width=1920)`, }} >
              <div className="container --layout --fullH --centerV">
                <div className="texts --flex-col fc-white">
                  <Fade direction="down">
                    <div className="title fw-b tt-caps fc-blue-7 fs-4">
                    {lifeData[0].flatData.section4Title}
                  </div>
                  </Fade>
                  <Fade direction="up">
                  <div
                    className="text -pt12 fs-7 fc-blue-2 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section4Subtitle,
                    }}
                  />
                  </Fade>
                  <Fade direction="up" delay={100}>
                  <div
                    className="text -pt12 fs-7 fc-blue-6 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section4Text,
                    }}
                  />
                  </Fade>
                </div>
              </div>
            </div>

            <div id="" className="section --fullWH section-4 light --right" style={{ backgroundImage: `url(${lifeData[0].flatData.section43Background[0].url}?cache=600&quality=90&width=1920)`, }} >
              <div className="container --layout --fullH --centerV">
                <div className="texts --flex-col fc-white">
                  <Fade direction="down">
                    <div className="title fw-b fc-blue-10 tt-caps fs-4" dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section43Title,
                    }}/>
                  </Fade>
                  <Fade direction="up">
                  <div
                    className="text -pt12 fs-7-1 fc-blue-2 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section43Subtitle,
                    }}
                  />
                  </Fade>
                  <Fade direction="up" delay={100}>
                  <div
                    className="text -pt12 fs-7-1 fc-blue-2 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section43Text,
                    }}
                  />
                  </Fade>
                </div>
                <Fade direction="up">
                  <div className="section-image -pt65" style={{justifyContent: 'flex-end', display: 'flex'}}>
                  <img src={`${lifeData[0].flatData.section43Image[0].url}?cache=600&quality=90&width=500`} alt="" />
                </div>
                  </Fade>
              </div>
            </div>

            <div id="" className="section --fullWH section-4 light" style={{ backgroundImage: `url(${lifeData[0].flatData.section44Background[0].url}?cache=600&quality=90&width=1920)`, }} >
              <div className="container --layout --fullH --centerV">
                <div className="texts --flex-col fc-white">
                  <Fade direction="down">
                    <div className="title fw-b tt-caps fs-4">
                    {lifeData[0].flatData.section44Title}
                  </div>
                  </Fade>
                  <Fade direction="up">
                  <div
                    className="text -pt65 fs-7-1 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section44Subtitle,
                    }}
                  />
                  </Fade>
                  <Fade direction="up" delay={100}>
                  <div
                    className="text -pt12 fs-7-1 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section44Text,
                    }}
                  />
                  </Fade>
                </div>
              </div>
            </div>

              <div id="section-5" className="section section-5 --pt6 bg-blue-7 light">
            
              <div className="container --fullW">
              
                <div className="texts fc-white --layout --center">
                <Fade direction="left">
                  <div className="section-logo">
                    <svg>
                      <use xlinkHref="#life-logo-notext" />
                    </svg>
                  </div>
                  </Fade>
                  <Fade direction="right" delay={200}>
                  <div
                    className="text -pt12 fs-7 fw-b tt-caps"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section5Text,
                    }}
                  />
                  </Fade>
                </div>
              </div>
            </div>

            <div ref={ref} id="section-5a" className="section  -pt2  section-5a bg-light-grey dark">
              <div className="container --fullW">
                <div className="texts fc-white --layout  --flex-row --center">
                    {isOnScreen && (
                  <div className="section-schema schema-desktop">
                      <>
                      {/* <svg className="schema-desktop">
                        <use xlinkHref="#info-temp" />
                      </svg>
                      <svg className="schema-mobile">
                        <use xlinkHref="#info-tmp-mobile" />
                      </svg>  */}
                      {/* <svg className="schema-desktop">
                        <use xlinkHref="#schema" />
                      </svg>
                      <svg className="schema-mobile">
                        <use xlinkHref="#schema-mobile" />
                      </svg> */}
                      </>
                  </div>
                    )}
                </div>
              </div>
            </div>
            <div id="who-we-are" className="section --fullWH section-6 dark" style={{ backgroundImage: `url(${lifeData[0].flatData.section6Background[0].url}?cache=600&quality=90&width=1920)`, }} >
              <div className="container --layout -padTB">
                <div className="texts --flex-col">
                  <Fade direction="up">
                  <div className="title fc-orange-2 fw-m tt-caps fs-3">
                    {lifeData[0].flatData.section6Title}
                  </div>
                  </Fade>

                  <div className="container layout-medium">
                  <Fade direction="up">
                    <div
                      className="text -pt65 fc-blue-4 fs-7 fw-l"
                      dangerouslySetInnerHTML={{
                        __html: lifeData[0].flatData.section6Text,
                      }}
                    />
                    </Fade>
                  </div>
                </div>
                <div className="container peopleList layout-medium">
                {lifeData[0].flatData.section6People.map((item: any, i: number) => (
                  
                  <div className={`people teamMember-${i} ${activeItem === `teamMember-${i}`  ? 'active' : ''}`} key={i}>
                    <div className="peopleContainer">
                    <div className="closeIcon" onClick={() => { setActiveItem(''); }}>
                    <svg>
                      <use xlinkHref="#close" />
                    </svg>
                    </div>
                    <div className="peopleImage" style={{ backgroundImage: `url(${item.image[0].url}?cache=600&quality=90)`}}  onClick={ () => setActiveItem(`teamMember-${i}`) }></div>
                    <div className="peopleTexts"  onClick={ () => setActiveItem(`teamMember-${i}`) }>
                      <div className="peopleTitle fw-sb fs-4 fc-blue-1">{item.title}</div>
                      <div className="peopleSubtitle fw-sb fs-2 fc-blue-7 tt-caps">{item.subtitle}</div>
                    <div
                    className="text fs-3 fw-l fc-blue-1 -pt2"
                    dangerouslySetInnerHTML={{
                      __html: item.shortBioText,
                    }}
                  />
                    </div>

                    </div>
                  </div>

                ))}
                </div>
              </div>
            </div>
            <div id="section-7" className="section section-7 --pt6 bg-blue-1 light">
              <div className="container --fullW">
                
                <div className="texts fc-white --layout  --flex-row --center">
                  <Fade>
                  <div
                    className="text fs-7 fw-l text-center"
                    dangerouslySetInnerHTML={{
                      __html: lifeData[0].flatData.section7Text,
                    }}
                  />
                  </Fade>
                </div>
              </div>
            </div>
            <div id="contact" className="section section-8 --pt6 bg-blue-9 dark">
              <div className="container layout-medium">
              
              <div className="-pt55 container layout-small">
                    <ContactForm />
              </div>
              </div>
            </div>
          </div>
          <Footer navData={lifeData[0].flatData.footerItems} />
        </div>
      
      </>
      )}
    </>
  );
}

export default Life;
