import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AppContextProvider, AppContext } from "../context/AppContext";
import ScrollToTop from "../lib/scrollToTop";
import Main from "./Main";
import "../styles/main.scss";

import { ReactComponent as UISvg } from "../assets/ui/ui.svg";


function App() {
  return (
    <BrowserRouter>
      <Route
        render={(routerProps: any) => (
          <AppContextProvider>
            <AppContext.Consumer>
              {(appctx) => (
                <div className="layout">
                  <Main routerProps={routerProps} />
                  <UISvg />
                  <ScrollToTop />
                </div>
              )}
            </AppContext.Consumer>
          </AppContextProvider>
        )}
      />
    </BrowserRouter>
  );
}

export default App;
