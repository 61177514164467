import React, { ReactElement  } from "react";
import "./Animation.scss";


type Props = {
    animationData: any;
};

export default function Animation(props: Props): ReactElement {
    const {animationData} = props;
  return (
    <>

    { animationData  && (
    <div className="bgIntro">
      <div className="siteSelection --layout">
      
          <svg>
            <use xlinkHref="#life-logo3" />
          </svg>
        
      </div>
      
      <div className="hometext">
          <h1>{animationData.flatData.introText }</h1>
        </div>
    </div>
    )}
    </>
  );
}

