import React, { ReactElement, useState, useEffect } from "react";
import { Link } from 'react-scroll'
import "./Header.scss";

type Props = {
  navData: any;
  logo: any;
};

export default function Header(props: Props): ReactElement {
  const { navData, logo } = props;
  const [mobileNav, setMobileNav] = useState(false);
  const [headerTheme, setHeaderTheme] = useState("headerLight");



  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;
      const sections = document.querySelectorAll('.section');
      const b = document.querySelector('header');
      if (prevScrollpos > currentScrollPos) {
        b?.classList.remove('headerHidden');
        if (currentScrollPos>0) {
          b?.classList.add('headerVisible');
          b?.classList.add('withBg');
        } else {
          b?.classList.remove('headerVisible');
          b?.classList.remove('withBg');
        }
        for (i = 0; i < sections.length; ++i) {
          sections[i].getBoundingClientRect()
          const aR = sections[i].getBoundingClientRect()
          const bR = b?.getBoundingClientRect()
          const el = document.querySelector('.blue-2');

          if (aR!.left >= bR!.right || aR!.top >= bR!.bottom || aR!.right <= bR!.left || aR!.bottom <= bR!.top) {
          } else {
            if (sections[i]?.classList.contains('dark')) {
              setHeaderTheme('headerDark');
              el!.setAttribute('style','fill:#46ACCC')
            } else {
              setHeaderTheme('headerLight');
              el!.setAttribute('style','')
            }
          }
        }
      } else {
        document.querySelector('header')?.classList.add('headerHidden')
      }
      prevScrollpos = currentScrollPos;
    }
  }, []);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 1024) {
          setMobileNav(false);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    mobileNav
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [mobileNav]);

  return (
    <header id="header" className={`${headerTheme} ${mobileNav && ('mobileOpen')} `}>
      <div className="container header-layout">
        <div className="brand">
          {logo && (
            <svg>
              <use xlinkHref={`#${logo}-logo2`} />
            </svg>
          )}
        </div>
        <div className="nav">
          {navData.map((item: any, i: number) => (
            <Link key={i}
              activeClass="active"
              className="fs-9 fw-m"
              spy={true}
              smooth={true}
              duration={1000} to={`${item.navUrl}`} onClick={() => setMobileNav(false)}>{item.navTitle}</Link>
          ))}
        </div>
        <div className="mobile-nav">
          <div className="nav-icon" onClick={() => setMobileNav(!mobileNav)}>
            <span></span><span></span><span></span><span></span>
          </div>
        </div>
      </div>
    </header>
  );
}
