import React, { Suspense, ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "../components/Loader";
// import Home from "./Home/Home";
import Life from "./Life/Life";

type Props = {
  routerProps: any;
};

function Main(props: Props): ReactElement {
  // const {routerProps} = props;
  return (
    <Suspense fallback={<Loader />}>
        <Switch>
          {/* <Route
          exact
          path={`/pages/:slug`}
          render={(routerProps: any) => <Page settings={appctx.AppSettings} routerProps={routerProps} />}
        /> */}
          <Route exact path={`/`} render={(routerProps: any) => <Life />} /> 
           {/* <Route exact path={`/life`} render={(routerProps: any) => <Life />} /> */}
          </Switch>
    </Suspense>
  );
}

export default Main;
