import React, { ReactElement, useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
// import isEmail from "validator/lib/isEmail";
import { Fade } from "react-awesome-reveal";

import {
  apiUrl,
  authUrl,
  clientID,
  clientSecret,
} from "../../../config/settings.json";

import './ContactForm.scss';

export default function ContactForm(): ReactElement {
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  
  const handleValidation = (values: any) => {
    let errors: any = {};

    for (const key in values) {
      const value = `${values[key]}`;

      if (!value && key!=='website') {
        errors[key] = "This field is required";
      }
    }

    return errors;
  };

  

  const onSubmit = (
      
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void
  ) => {
    const url = `${apiUrl}/contactform/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', clientID);
    params.append('client_secret', clientSecret);
    params.append('scope', 'squidex-api');

    axios({
      method: "post",
      url: authUrl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Accept": "application/json",
      },
      data: params,
    })
      .then((response) => {
        const token = response.data.access_token;
        
        axios.post(url, (values), {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
        }).then((response) => {
          setSubmitting(false);
          if (response) {
            resetForm();
            setIsFormSuccess(true);
          }
        });

      })
      .catch((err) => {
        console.log(err);
      });

    // const authData = `grant_type=client_credentials&client_id=${cliendID}=&client_secret=${cliendSecret}&scope=squidex-api`;

    //     axios({
    //       method: 'POST',
    //       url: authUrl,
    //       headers: {
    //           // Authorization: `Bearer ${token}`,
    //           'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       data: authData
    //   })
    //   .then(response => {
    //       console.log(response);
    //   })
    //   .catch(error => {
    //       console.log(error);
    //   });

    // console.log(JSON.stringify(values))
        
  };

  return (
    <div className="ContactForm">
      
<Fade direction="down"><div className="title fc-orange-2  fw-m tt-caps fs-3 text-center">Contact</div></Fade>
{isFormSuccess && (
        <div className="ContactForm-success">
          <div className="text">Your message has been successfully sent!</div>
        </div>
      )}
              <Fade direction="up" delay={200}><div className="subtitle fc-blue-1 fw-sb -pt2 fs-5 text-center">Contact us by filling in the form.<br />
              You can also send an email to: <a href="mailto:contacts@aurelius.life" className="fc-blue-4">contacts@aurelius.life</a></div>
              </Fade>
      <Formik
        initialValues={{website: {iv: 'Life'}, profession: "", firstName: "", lastName: "", email: "", message: "" }}
        validate={(values) => {
          return handleValidation(values);
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ errors, touched, handleChange, isSubmitting }) => (
          <Form id="contact-form" className="form -pt2">
            <div className="form-fields">
            <div className="form-row">
                <div
                  className={`form-control select-row ${
                    touched.profession && errors.profession ? "has-error" : ""
                  }`}
                >
                   <Field as="select" className="select" name="profession['iv']" id="profession">
                    <option value="">Select profession</option>
                    <option value="Supplier of medical equipment">Supplier of medical equipment</option>
                    <option value="HC professional">HC professional</option>
                    <option value="Service provider">Service provider</option>
                    <option value="Other">Other</option>
                </Field>

                  {touched.profession && errors.profession && (
                    <p className="error-message select-error">{errors.profession}</p>
                  )}
                </div>
                </div>
              <div className="form-row --flex-row gap-2 ">
                <div
                  className={`form-control ${
                    touched.firstName && errors.firstName ? "has-error" : ""
                  }`}
                >
                  <Field type="text" name="firstName['iv']" id="firstName" placeholder="First name" />
                  <label htmlFor="firstName">First Name</label>
                  {touched.firstName && errors.firstName && (
                    <p className="error-message">{errors.firstName}</p>
                  )}
                </div>
                
                <div
                  className={`form-control ${
                    touched.lastName && errors.lastName ? "has-error" : ""
                  }`}
                >
                  <Field type="text" name="lastName['iv']" id="lastName" placeholder="Last name" />
                  <label htmlFor="lastName">Last Name</label>
                  {touched.lastName && errors.lastName && (
                    <p className="error-message">{errors.lastName}</p>
                  )}
                </div>
                <div
                  className={`form-control ${
                    touched.email && errors.email ? "has-error" : ""
                  }`}
                >
                  <Field type="email" name="email['iv']" id="email" placeholder="Email" />
                  <label htmlFor="email">EMAIL</label>
                  {touched.email && errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
              </div>

              <div className="form-row form-row-message -pt2">
                <div
                  className={`form-control ${
                    touched.message && errors.message ? "has-error" : ""
                  }`}
                >
                  <Field
                    as="textarea"
                    name="message['iv']"
                    id="message"
                  />
                  <label htmlFor="message">Your message</label>
                  {touched.message && errors.message && (
                    <p className="error-message --textarea">{errors.message}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="form-action">
              <button className="button --primary" type="submit" disabled={isSubmitting}>
                <span className="text">Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
