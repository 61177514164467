import React, { ReactElement,useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import "./Footer.scss";

type Props = {
  navData: any;
};


export default function Footer(props: Props): ReactElement {
  const { navData } = props;
  const date = new Date();
  const year = date.getFullYear(); 
  const [isShown, setIsShown] = useState<boolean>(false);
  const [pageData, setPage] = useState("");
  const toggle = () => setIsShown(!isShown);

  isShown
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  return (
    <>
    <footer className="container --fw">
      <div className="footer-inner">
      <nav>
        {navData.map((item: any, i: number) => (
          <div key={i} className="footer-link fs-4 fw-l" onClick={ () => {setIsShown(true); setPage(item.footerItemUrl)}}>{item.footerItemTitle}</div>
        ))}
      </nav>
      <div className="legal fw-sb fs-2">Copyright &copy; {year}, Aurelius, All Rights Reserved</div>
      </div>
    </footer>
    <Modal isShown={isShown} hide={toggle} page={pageData} data={navData} />
    <div id="modal"></div>
    </>
  );
}
